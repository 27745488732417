<template>
    
    <div class="footer">
        <div class="container">
            <div class="cols">
                <div class="logo">
                    <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M22.4228 15.8772L16.8726 18.9983L0 9.51145V9.50473L16.8976 0L33.772 9.49488L24.968 14.4459L17.5339 10.2625C17.2425 10.1146 16.9037 10.0819 16.5881 10.1713C16.2726 10.2607 16.0046 10.4653 15.8402 10.7424C15.6757 11.0195 15.6274 11.3477 15.7053 11.6586C15.7832 11.9696 15.9814 12.2392 16.2584 12.4114L22.4228 15.8772Z" fill="#FF59A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.8726 37.985L16.8976 37.9991L33.7896 28.4916V22.267L22.4159 15.8723L16.8726 18.9894V25.2132L17.534 25.585C17.811 25.7571 18.0092 26.0268 18.0871 26.3377C18.165 26.6486 18.1167 26.9769 17.9522 27.254C17.7878 27.5311 17.5198 27.7356 17.2043 27.825C17.0955 27.8559 16.984 27.8722 16.8726 27.8743V37.985ZM33.7655 9.49023L24.9611 14.441L33.7896 19.4091V9.50381L33.7655 9.49023Z" fill="#FF59A9"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 28.4978L16.8976 37.9998L16.9059 37.9951V27.874C16.683 27.8838 16.4594 27.8366 16.2584 27.7346L0 18.5925V28.4978ZM16.9059 25.2327L0 15.7292V9.50456L0.0185173 9.49414L16.9059 18.9901V25.2327Z" fill="#FF59A9"/>
</svg>


                    <div class="column">
                        <p><b>STIMA</b></p>
                        <p>2024(c)</p>
                    </div>
                </div>
            </div>

            <div class="cols">
                <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{item.id}}</router-link>
            </div>

            <div class="cols">
                <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{item.id}}</router-link>
            </div>

            <div class="cols">
                <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{item.id}}</router-link>
            </div>
            
            <div class="cols">
                <router-link class="item" v-for="(item, index) in action" :key="index" :to="item.link">{{item.id}}</router-link>
            </div>

            <!-- <div class="cols">
                <p class="item">Dark mode</p>
                <Mode></Mode>
            </div> -->
        </div>
    </div>


     <!-- support -->
     <Popup v-model="support">
            <template #icon><svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M28 52.5C43.463 52.5 56 41.531 56 28C56 14.469 43.463 3.5 28 3.5C12.537 3.5 0 14.469 0 28C0 34.16 2.6005 39.795 6.895 44.1C6.5555 47.656 5.4355 51.555 4.1965 54.481C3.92 55.132 4.4555 55.86 5.152 55.748C13.048 54.453 17.7415 52.465 19.782 51.429C22.4622 52.1464 25.2255 52.5065 28 52.5Z" fill="var(--primary)"/></svg></template>            
            <template #title>Support</template>      
            <template #line>Describe bug or suggestion.</template>    
            <div class="column w600">
              <textarea ref="supportMessage" class="w600" style="height: 300px;" placeholder="Describe bug/suggestion..."></textarea>
              <br>
              <div class="row center">                
                <p class="tag" @click="api.support($route, $refs.supportMessage.value, console.logs);support=false">Send</p>
              </div>
            </div>            
        </Popup>
    
    
</template>
<script>

export default {
    data: function () {return {
        action: [
            { id: 'home', link:'/'},
            { id: 'search', link:'/search'},
            { id: 'people', link:'/people'},
            { id: 'top', link:'/top'},
            { id: 'wait', link:'/wait'},
        ]
    }},
    components:{},
    props:{
        
    },
}
</script>


<style scoped>
.footer{
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    padding: 50px 0 400px;
    background: var(--g03);
    margin-top: 20vh;
}

.container{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: 1200px;
    background: none;

}

.cols{
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0 50px;
    align-items: flex-start;
    justify-content: flex-start;
}

.logo{
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
}
.logo p{
    color:  var(--text);
    text-align: left;
    font-size: 11px;
    line-height: 15px;
    margin-left: 10px;
}


.item{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    letter-spacing: 0.0em;
    color:  var(--g4);
    text-decoration: none;
    margin: 0 0px 6px;
    white-space: nowrap;
}
.item.router-link-exact-active{
    opacity: .3;
}
</style>

