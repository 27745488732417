<template>      
      <div class="screen column" style="overflow: hidden; width: 100vw; height: 100vh;">
          
          <transition name="titlefade" mode="out-in" appear>
            <div class="column left" style="width: 100vw; padding-left: 10vw; ">
              <p class="mini">Stima today:</p>
              <p class="title">USD {{tools.fm(dict.totalStimaUSD ,',') }}</p>              
              <p class="title">{{tools.fm(dict.totalStimaAssets,',') }} assets</p>              
              <p class="title">{{tools.fm(dict.totalStimaUsers,',') }} users</p>                            
            </div>            
          </transition>     

          <div style="width: 100vw;">
            <apexchart type="area" height="400" :options="chartOptions" :series="series"></apexchart>
          </div>
          
          <!-- <transition name="imagefade" mode="out-in">
            <div class="column center" style="position: absolute; width: 40vw;  right: 0;">              
              
            </div>
          </transition> -->


          <!-- totalPoints -->
           <br>
          
        
      </div>

      

</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  data() {
    return {
      
    }
  },
  mounted(){
    this.dict.refresh()
  },

  computed:{
    items(){
      let nums = []// [11, 32, 45, 32, 34, 52, 41]
      let dates = []// ["2024-06-11","2024-06-12","2024-06-13","2024-06-14","2024-06-15","2024-06-16","2024-06-21",]

      this.dict.totalPoints.forEach(p => {
        nums.push(p.value)
        dates.push(this.tools.unixToJSdate(p.unix))
      });

      console.log('lines total stima', nums, dates)
      
      return {nums, dates}
    },

    series(){ 
      return [{
            name: 'Total',
            data: this.items.nums, 
            color: "#48BA91",           
          }, 
          // {
          //   name: 'series2',
          //   data: [11, 32, 45, 32, 34, 52, 41]
          // }
        ]
    },

    chartOptions(){
      let fm = this.tools.fm
      return {
            chart: { width: '100%', height: 400, type: 'line',toolbar:{show: false},background: 'none'},
            grid:{show: false},            
            dataLabels: {enabled: false},
            legend:{show: false},
            stroke: {curve: 'smooth',show: true,lineCap: 'butt',width: 10},
            theme: {mode: 'dark'},
            xaxis: {
              axisBorder: {show: false},
              axisTicks: {show: false},
              // crosshairs:{show: false},
              type: 'category',              
              // type: 'none',              
              // categories: this.items.dates,
              labels:{
                show: false,
                hideOverlappingLabels: true,
                showDuplicates: false
              }
            },
            yaxis:{show: false},
            tooltip: {    
              // enabled: false,    
              followCursor: true,        
              x: {
                show: false,
                // format: 'dd/MM/yy HH:mm'
                format: 'dd MM yyyy'
              },
              y: {
                formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {                  
                  return '$'+fm(value, '.')
                }
              }
            },
          }
    },
  },

  methods:{

  },
  });


</script>

<style scoped>
  
.title{
  font-style: normal;
  font-weight: 110;
  font-size: 104px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.745);
}



.imagefade-enter-active {
  transition: opacity 1s;
}
.imagefade-leave-active {
  transition: opacity 2s;
}
.imagefade-enter-from, .imagefade-leave-to {
  opacity: 0;
}
.imagefade-enter-to {
  opacity: 1;
}


.buttonfade-enter-active {
  transition: opacity 2s;
}
.buttonfade-leave-active {
  transition: opacity 1s;
}
.buttonfade-enter-from, .buttonfade-leave-to {
  opacity: 0;
}
.buttonfade-enter-to {
  opacity: 1;
}



.titlefade-enter-active {
  transition: opacity 2s;
}
.titlefade-leave-active {
  transition: opacity 1.5s;
}
.titlefade-enter-from, .titlefade-leave-to {
  opacity: 0;
}
.titlefade-enter-to {
  opacity: 1;
}
</style>