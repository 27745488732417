<template>
    
   <div class="column center" style="padding: 20px 30px;">   
       
        <!-- main -->
        <div class="column center" v-if="page == 'main'">
            <input v-model="email" class="w300" type="text" placeholder="Email"> 
            <p class="button" @click="welcome()" style="width: 300px;">Next</p>
            
            <p class="t3 mb2">or</p>
            
            <div @click="loginWithGoogle" class="button buttonLogin pointer click w300" style="background-color: white;"> <span class="icon iconGoogle"></span> <p class="label" style="color: black;">Login with Google</p> </div>        
            <!-- <div @click="loginWithGoogle" class="button buttonLogin pointer click w300" style="background-color: black;"> <span class="icon iconApple"></span> <p class="label" style="color: white;">Login with Apple</p> </div>                 -->
            <p class="micro w220">Transfer your assets into a digital format and dispose of them.</p>        
        </div>
        
        
        <!-- signup -->
        <div class="column center" v-if="page == 'signup'">            
            <input disabled v-model="email" class="w300" type="text" placeholder="Email"> 
            <input v-model="pass" class="w300" type="text" placeholder="Password"> 
            <input v-model="name" class="w300" type="text" placeholder="Name"> 
            <p class="button" @click="signup()" style="width: 300px;">Signup</p>            
            <p class="micro w220">Transfer your assets into a digital format and dispose of them.</p>        
        </div>
        
        
        <!-- verify -->
        <div class="column center" v-if="page == 'verify'">            
            <p>We have sent a confirmation link to your email {{ email }}</p>
            <p class="button" style="width: 300px;" @click="$emit('close', true)">OK</p>            
            <!-- <p class="micro w220">Transfer your assets into a digital format and dispose of them.</p>         -->
        </div>
        
        
        <!-- login -->
        <div class="column center" v-if="page == 'login'">            
            <input disabled v-model="email" class="w300" type="text" placeholder="Email"> 
            <input v-model="pass" class="w300" type="text" placeholder="Password">             
            <p class="button" @click="login()" style="width: 300px;">Login</p>            
            <p class="micro w220">Transfer your assets into a digital format and dispose of them.</p>        
        </div>

        <!-- verify -->
        <div class="column center" v-if="page == 'error'">            
            <p>Please try again later</p>
            <p class="button" style="width: 300px;" @click="$emit('close', true)">OK</p>            
            <!-- <p class="micro w220">Transfer your assets into a digital format and dispose of them.</p>         -->
        </div>

    <!-- <br> -->
   </div>


</template>
<script>
import { googleTokenLogin } from "vue3-google-login";
export default {
    data: function () {return {
        page:'main',
        showEmailLogin: false,
        email: undefined,
        pass: undefined,
        name: undefined,
    }},
    mounted() {
        if (this.dev){
            this.email = 'a@senthy.com'
            this.pass = '12345'
        }
    },
    methods: {
    
    async welcome(){
        let email = this.email
        if (!email) return
        let res = await this.api.account.welcome(email)
        console.log(res)
        if (res.error){
            return
        }
        if (res.go == 'login') this.page = 'login'
        if (res.go == 'signup') this.page = 'signup'
    },
        
    loginWithGoogle() {        
        googleTokenLogin().then((response) => {            
            this.google(response)
        })
    },

   async google(resp){                
        let token = resp.access_token
        let res = await this.db.loginGoogle(token)
        if (res.error) {
            console.error(res.error)
            return
        }                    
         window.location = '/'
   },

   async apple(resp){                
        let token = resp.access_token
        let res = await this.db.loginApple(token)
        if (res.error) {
            console.error(res.error)
            return
        }                    
         window.location = '/'
   },
   
   async login(){                
        if (!this.email || !this.pass) return
        let res = await this.db.loginEmail(this.email, this.pass)
        if (res.error) {
            console.error(res.error)
            return
        }                    
        window.location = '/'
   },
   
   
   async signup(){                
        if (!this.email || !this.pass || !this.name) return
        let res = await this.api.account.signup(this.email, this.pass, this.name)
        if (res.error) {
            console.error(res.error)
            return
        }                    
        if (res.go == 'verify'){
            this.page = 'verify'
        }
   },

   

  },
}
</script>
<style scoped>


.buttonLogin{
    /* font-family: var(--titleFonts); */
    position: relative;
    border-radius: 10px;
    height: 46px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px 0;
    font-size: 20px;
    line-height: 18px;
    cursor: pointer;
    user-select: none;    
    width: 300px;
    /* width: 100%; */
    /* letter-spacing: -0.02em; */
}

.label{
    color: var(--text);
    opacity: .7;
    margin-left: 12px;
}

.apple{
    background: rgb(255, 255, 255);
    border: 1px solid rgba(0, 0, 0, 0);
    color: white;
}

.icon{
    position: absolute;
    left: 12px;
}

.iconGoogle{    
    margin-top: 4px;
    margin-left: 6px;
}
.iconGoogle::before{
    content: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1664_716)'%3E%3Cpath d='M25.1195 13.2432C25.1195 12.178 25.034 11.4007 24.8489 10.5946H12.8164V15.4024H19.8792C19.7369 16.5972 18.9679 18.3966 17.2591 19.6057L17.2352 19.7666L21.0397 22.746L21.3032 22.7726C23.7239 20.5126 25.1195 17.1874 25.1195 13.2432Z' fill='%234285F4'/%3E%3Cpath d='M12.8179 25.9106C16.2781 25.9106 19.1829 24.759 21.3047 22.7726L17.2606 19.6056C16.1784 20.3686 14.7259 20.9012 12.8179 20.9012C9.42884 20.9012 6.55245 18.6412 5.5271 15.5176L5.37681 15.5305L1.42087 18.6254L1.36914 18.7708C3.47659 23.0028 7.80546 25.9106 12.8179 25.9106Z' fill='%2334A853'/%3E%3Cpath d='M5.52689 15.5177C5.25634 14.7116 5.09976 13.8478 5.09976 12.9554C5.09976 12.0628 5.25634 11.1992 5.51265 10.3931L5.50549 10.2214L1.49998 7.07678L1.36893 7.1398C0.500347 8.89599 0.00195312 10.8681 0.00195312 12.9554C0.00195312 15.0426 0.500347 17.0146 1.36893 18.7708L5.52689 15.5177Z' fill='%23FBBC05'/%3E%3Cpath d='M12.8179 5.00937C15.2243 5.00937 16.8476 6.06018 17.7732 6.93833L21.3901 3.36839C19.1688 1.28115 16.2781 0 12.8179 0C7.80546 0 3.47659 2.90773 1.36914 7.13978L5.51287 10.3931C6.55245 7.26938 9.42884 5.00937 12.8179 5.00937Z' fill='%23EB4335'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1664_716'%3E%3Crect width='25.1333' height='26' fill='white'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E%0A");
}

.iconTelegram{margin-top: 4px;}
.iconTelegram::before{
    content: url("data:image/svg+xml,%3Csvg width='26' height='26' viewBox='0 0 26 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 0C9.55297 0 6.24406 1.37048 3.80859 3.80758C1.37061 6.24564 0.000675329 9.55212 0 13C0 16.4464 1.37109 19.7553 3.80859 22.1924C6.24406 24.6295 9.55297 26 13 26C16.447 26 19.7559 24.6295 22.1914 22.1924C24.6289 19.7553 26 16.4464 26 13C26 9.55358 24.6289 6.24467 22.1914 3.80758C19.7559 1.37048 16.447 0 13 0Z' fill='url(%23paint0_linear_1664_626)'/%3E%3Cpath d='M5.88051 12.8623C9.67082 11.2113 12.1977 10.1228 13.4611 9.59687C17.0727 8.09516 17.8222 7.83435 18.3118 7.82552C18.4194 7.82379 18.6591 7.8504 18.8155 7.97684C18.9455 8.08348 18.9821 8.2277 19.0004 8.32896C19.0166 8.43012 19.0389 8.66067 19.0207 8.84063C18.8257 10.8963 17.9786 15.8846 17.548 18.187C17.3672 19.1612 17.0077 19.4878 16.6604 19.5197C15.9047 19.5892 15.3319 19.0208 14.6007 18.5417C13.4571 17.7915 12.8111 17.3248 11.7 16.5929C10.4163 15.7471 11.2491 15.2821 11.9804 14.5224C12.1713 14.3236 15.4985 11.298 15.5614 11.0236C15.5696 10.9893 15.5777 10.8613 15.5005 10.7939C15.4254 10.7262 15.3136 10.7494 15.2324 10.7677C15.1166 10.7937 13.2905 12.0019 9.74801 14.392C9.23004 14.7483 8.76082 14.922 8.33832 14.9129C7.8752 14.9029 6.98145 14.6504 6.31723 14.4347C5.50473 14.17 4.85676 14.0301 4.91363 13.5806C4.94207 13.3466 5.26504 13.1071 5.88051 12.8623Z' fill='white'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1664_626' x1='1300' y1='0' x2='1300' y2='2600' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%232AABEE'/%3E%3Cstop offset='1' stop-color='%23229ED9'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E%0A");
}
.iconApple{margin-top: 2px;left: 17px;}
.iconApple::before{
    content: url("data:image/svg+xml,%3Csvg width='22' height='26' viewBox='0 0 22 26' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.17098 6.00117C6.92909 6.00117 7.84387 6.15856 8.91532 6.47335C9.99689 6.78813 10.7146 6.94552 11.0683 6.94552C11.5232 6.94552 12.2662 6.76782 13.2972 6.41242C14.3282 6.05702 15.2228 5.87932 15.9809 5.87932C17.2242 5.87932 18.331 6.21441 19.3014 6.88459C19.8472 7.27046 20.388 7.7934 20.9237 8.45343C20.1151 9.14392 19.5238 9.75825 19.1498 10.2964C18.4725 11.2712 18.1339 12.3476 18.1339 13.5255C18.1339 14.8151 18.4927 15.9777 19.2104 17.0135C19.9281 18.0492 20.7468 18.7042 21.6667 18.9783C21.2826 20.2273 20.6457 21.5321 19.7562 22.8928C18.4119 24.9338 17.0776 25.9543 15.7534 25.9543C15.2278 25.9543 14.5 25.7868 13.5701 25.4517C12.6503 25.1166 11.8719 24.949 11.2351 24.949C10.5983 24.949 9.85538 25.1217 9.0063 25.4669C8.16733 25.8223 7.48503 26 6.95941 26C5.37244 26 3.8158 24.6495 2.28948 21.9484C0.76316 19.2779 0 16.6581 0 14.089C0 11.7028 0.581214 9.75825 1.74364 8.25542C2.91618 6.75259 4.39196 6.00117 6.17098 6.00117ZM15.7231 0C15.7635 0.132005 15.7888 0.24878 15.7989 0.350322C15.809 0.451865 15.8141 0.553408 15.8141 0.65495C15.8141 1.30482 15.6625 2.01562 15.3592 2.78735C15.056 3.55907 14.5758 4.27495 13.9188 4.93497C13.3528 5.49346 12.7918 5.86917 12.2358 6.0621C11.882 6.17379 11.3463 6.26011 10.6286 6.32103C10.6489 4.77758 11.0481 3.4423 11.8265 2.31517C12.6149 1.18805 13.9138 0.416325 15.7231 0Z' fill='white'/%3E%3C/svg%3E%0A");
}

.iconLinkedin{margin-top: 5px;left: 11px;}
.iconLinkedin::before{
    content: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_1958_292)"><rect x="2" y="2" width="22" height="22" fill="white"/><path d="M22.1532 22.1536H18.3009V16.1205C18.3009 14.6818 18.2752 12.8298 16.2972 12.8298C14.2908 12.8298 13.9837 14.3974 13.9837 16.0158V22.1532H10.1315V9.74671H13.8297V11.4422H13.8815C14.2516 10.8094 14.7864 10.2888 15.429 9.93584C16.0715 9.58291 16.7978 9.41088 17.5304 9.43806C21.435 9.43806 22.1548 12.0064 22.1548 15.3476L22.1532 22.1536ZM5.78459 8.05092C4.5499 8.05113 3.5488 7.05033 3.54859 5.81563C3.54839 4.58094 4.54909 3.57984 5.78378 3.57963C7.01848 3.57933 8.01958 4.58013 8.01978 5.81482C8.01989 6.40775 7.78446 6.97643 7.36528 7.39578C6.94611 7.81513 6.37752 8.05079 5.78459 8.05092ZM7.71083 22.1537H3.8544V9.74671H7.71073V22.1536L7.71083 22.1537ZM24.0738 0.0018923H1.91862C0.871508 -0.00988895 0.0127969 0.828916 0 1.87603V24.1236C0.0123906 25.1712 0.871 26.0108 1.91852 25.9999H24.0738C25.1235 26.0129 25.9855 25.1732 26 24.1236V1.8743C25.9851 0.825158 25.123 -0.0135452 24.0738 0.000165739" fill="%230A66C2"/></g><defs><clipPath id="clip0_1958_292"><rect width="26" height="26" fill="white"/></clipPath></defs></svg>');
}

.iconFacebook{margin-top: 5px;left: 11px;}
.iconFacebook::before{
    content: url('data:image/svg+xml,<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><g clip-path="url(%23clip0_1958_288)"><path d="M26 13C26 5.82034 20.1797 0 13 0C5.82034 0 0 5.82034 0 13C0 19.4886 4.75394 24.8669 10.9688 25.8421V16.7578H7.66797V13H10.9688V10.1359C10.9688 6.87781 12.9096 5.07812 15.8791 5.07812C17.3014 5.07812 18.7891 5.33203 18.7891 5.33203V8.53125H17.1498C15.5349 8.53125 15.0312 9.53337 15.0312 10.5615V13H18.6367L18.0604 16.7578H15.0312V25.8421C21.2461 24.8669 26 19.4887 26 13Z" fill="%231877F2"/><path d="M18.0604 16.7578L18.6367 13H15.0312V10.5615C15.0312 9.53327 15.5349 8.53125 17.1498 8.53125H18.7891V5.33203C18.7891 5.33203 17.3014 5.07812 15.879 5.07812C12.9096 5.07812 10.9688 6.87781 10.9688 10.1359V13H7.66797V16.7578H10.9688V25.8421C11.6407 25.9474 12.3198 26.0002 13 26C13.6802 26.0002 14.3593 25.9474 15.0312 25.8421V16.7578H18.0604Z" fill="white"/></g><defs><clipPath id="clip0_1958_288"><rect width="26" height="26" fill="white"/></clipPath></defs></svg>');
}
</style>