<template>      
      <div class="screen column" style="overflow: hidden; width: 100vw; height: 100vh;">
              
        <div class="row">
       
            <div class="column left" style="margin-right: 5vw;">
              <p class="mini">Crypto</p>
              <br>
              <p class="title w600">STIMA is a crypto-currency that allows for the convertion of an asset into our token and back to an asset in our pool of available assets.</p>
              <br>
             
              <div class="row">
                <a class="linkcolor mr8" href="#">White Paper</a>
                <a class="linkcolor" href="#">Presentation</a>
              </div>
              
            </div>    


            <img src="/images/landing/one/convert.png" style="width: auto;height: 25vh;">
            
          </div>


      </div>
</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  data() {
    return {
      
    }
  },
  mounted(){
    // 
  },

  computed:{
    
  },

  methods:{

  },
  });


</script>

<style scoped>
  
.title{
  font-style: normal;
  font-weight: 200;
  font-size: 40px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.745);
}




.imagefade-enter-active {
  transition: opacity 1s;
}
.imagefade-leave-active {
  transition: opacity 2s;
}
.imagefade-enter-from, .imagefade-leave-to {
  opacity: 0;
}
.imagefade-enter-to {
  opacity: 1;
}


.buttonfade-enter-active {
  transition: opacity 2s;
}
.buttonfade-leave-active {
  transition: opacity 1s;
}
.buttonfade-enter-from, .buttonfade-leave-to {
  opacity: 0;
}
.buttonfade-enter-to {
  opacity: 1;
}



.titlefade-enter-active {
  transition: opacity 2s;
}
.titlefade-leave-active {
  transition: opacity 1.5s;
}
.titlefade-enter-from, .titlefade-leave-to {
  opacity: 0;
}
.titlefade-enter-to {
  opacity: 1;
}
</style>