<template>      
      <div class="screen" style="overflow: hidden; width: 100vw; height: 100vh;">
        <transition name="titlefade" mode="out-in" appear>
          
            <div v-if="start" :key="index" class="column left" style="width: 55vw; margin-right: 10vw; position: absolute; left: 10vw;">
              <p class="title">What’s My <span :key="index">{{ current.title }}</span> Worth Today? </p>
              <br><br><br>
              <transition name="buttonfade" mode="out-in" appear>              
              <div class="row">
                <p class="signupButton pointer click"  @click="showLogin = true" :style="{backgroundColor: current.color}">Create an account</p>
              </div>              
            </transition>
            </div>
          </transition>

          
          <!-- <p style="position: absolute;bottom: 30px;" @click="next()">next</p> -->
            
          <transition name="imagefade" mode="out-in">
            <div v-if="start" :key="index" class="column center" style="position: absolute; width: 40vw;  right: 0;">              
              <img v-if="!current.left" :src="current.image" style="width: auto; height: 50vh">
              <img v-if="current.left" :src="current.image" style="width: 35vw; height: auto; position: absolute; right: 0;">              
            </div>
          </transition>

        
      </div>

      
      
      
    <!-- login -->
    <Popup v-model="showLogin">
        <template #icon>
          <br>
           <svg width="34" height="38" viewBox="0 0 34 38" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.4228 15.8772L16.8726 18.9983L0 9.51145V9.50473L16.8976 0L33.772 9.49488L24.968 14.4459L17.5339 10.2625C17.2425 10.1146 16.9037 10.0819 16.5881 10.1713C16.2726 10.2607 16.0046 10.4653 15.8402 10.7424C15.6757 11.0195 15.6274 11.3477 15.7053 11.6586C15.7832 11.9696 15.9814 12.2392 16.2584 12.4114L22.4228 15.8772Z" fill="#ffffff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M16.8726 37.985L16.8976 37.9991L33.7896 28.4916V22.267L22.4159 15.8723L16.8726 18.9894V25.2132L17.534 25.585C17.811 25.7571 18.0092 26.0268 18.0871 26.3377C18.165 26.6486 18.1167 26.9769 17.9522 27.254C17.7878 27.5311 17.5198 27.7356 17.2043 27.825C17.0955 27.8559 16.984 27.8722 16.8726 27.8743V37.985ZM33.7655 9.49023L24.9611 14.441L33.7896 19.4091V9.50381L33.7655 9.49023Z" fill="#ffffff"/><path fill-rule="evenodd" clip-rule="evenodd" d="M0 28.4978L16.8976 37.9998L16.9059 37.9951V27.874C16.683 27.8838 16.4594 27.8366 16.2584 27.7346L0 18.5925V28.4978ZM16.9059 25.2327L0 15.7292V9.50456L0.0185173 9.49414L16.9059 18.9901V25.2327Z" fill="#ffffff"/></svg></template>            
        <template #title>Login</template>      
        <template #line>Login with email or other options</template>    
        <Login/>
    </Popup>

</template>

<script>
import { defineComponent } from 'vue';
export default defineComponent({
  data() {
    return {
      showLogin: false,
      current: {},
      start: false,
      index: -1,
      timer: undefined,
      items:[
        {title: 'Rolex', color:'#006A3E', image:'/images/assets/rolex.png'},
        {title: 'Hirst', color:'#00ABE1', image:'/images/assets/hirst.png'},
        {title: 'Rolls Royce', color:'#A46127', image:'/images/assets/rolls.png', left: true},
        {title: 'Villa', color:'#B47740', image:'/images/assets/villa.png', left: true},
        {title: 'Monza', color:'#B0031E', image:'/images/assets/monza.png', left: true},
        {title: 'Birkin', color:'#FF4346', image:'/images/assets/birkin.png'},
        {title: 'Monet', color:'#863E34', image:'/images/assets/monet.png'},
        
      ],
    }
  },
  mounted(){
    this.next()
    this.timer = setInterval(this.next, 5000);  
    this.start = true  
  },

  methods:{

    next(){
      this.index++
      if (this.index == this.items.length) this.index = 0
      this.current = this.items[this.index]
    }
  },
  });


</script>

<style scoped>
  
.title{
  font-style: normal;
  font-weight: 110;
  font-size: 104px;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: rgba(255, 255, 255, 0.745);
}



.imagefade-enter-active {
  transition: opacity 1s;
}
.imagefade-leave-active {
  transition: opacity 2s;
}
.imagefade-enter-from, .imagefade-leave-to {
  opacity: 0;
}
.imagefade-enter-to {
  opacity: 1;
}


.buttonfade-enter-active {
  transition: opacity 2s;
}
.buttonfade-leave-active {
  transition: opacity 1s;
}
.buttonfade-enter-from, .buttonfade-leave-to {
  opacity: 0;
}
.buttonfade-enter-to {
  opacity: 1;
}



.titlefade-enter-active {
  transition: opacity 2s;
}
.titlefade-leave-active {
  transition: opacity 1.5s;
}
.titlefade-enter-from, .titlefade-leave-to {
  opacity: 0;
}
.titlefade-enter-to {
  opacity: 1;
}
</style>